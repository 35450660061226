<template lang="">
  <div>
    <IAmOverlay :loading="loading || loadingGetTemplateId || loadingGetCompanyCertificates">
      <BAlert
        v-if="!isCompanyActive"
        show
        variant="danger"
        class="py-1 px-2"
      >
        {{ $t('invoice.msg.companyNotFoundOrDeactivated') }}
      </BAlert>
      <ValidationObserver ref="refFormObserver">
        <div
          v-if="invoiceToEdit"
          id="invoice-detail"
          class="pb-2"
        >
          <InvoiceInfoCard
            :invoice-data.sync="invoiceToEdit"
            :isEditing="isEditing"
            :isAdjust="isAdjust"
            :isReplace="isReplace"
            :isDuplicate="isDuplicate"
          />
          <BRow>
            <BCol
              cols="12"
              :md="collapseSelectedTicket ? 12 : 5"
              :class="!collapseSelectedTicket ? 'pr-md-50' : ''"
            >
              <InvoiceSelectTicketCard
                :invoice-data.sync="invoiceToEdit"
                :collapse-selected-ticket.sync="collapseSelectedTicket"
                :isEditing="[ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT].includes(invoiceToEdit?.adjustmentType) ? false :isEditing"
                :isAdjust="isAdjust"
                :isReplace="isReplace"
                :isDuplicate="isDuplicate"
              />
            </BCol>
            <BCol
              cols="12"
              :md="collapseSelectedTicket ? 12 : 7"
              :class="!collapseSelectedTicket ? 'pl-md-50' : ''"
            >
              <InvoiceDetailCard
                :invoice-data.sync="invoiceToEdit"
                :isEditing="isEditing"
                :resetInvoice="resetInvoice"
                :isAdjust="isAdjust"
                :isReplace="isReplace"
                :isDuplicate="isDuplicate"
                :isInvalidInvoiceItems="isInvalidInvoiceItems"
                @update-invoice="updateInvoiceByField"
              />
              <InvoicePaymentCard
                :invoice-data.sync="invoiceToEdit"
                :isEditing="isEditing"
                :isAdjust="isAdjust"
                :isReplace="isReplace"
                :isDuplicate="isDuplicate"
                @toggle-button="onToggleAllButtonActions"
              />

              <!-- ANCHOR Buttons -->
              <div class="d-flex flex-column gap-2 mb-50">
                <BRow
                  align-v="center"
                  class="button-actions"
                >
                  <BCol cols="3">
                    <BButton
                      v-if="!isAdjust && !isReplace && !isDuplicate"
                      pill
                      class="w-100"
                      variant="info"
                      :disabled="(!BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.SEND_EMAIL || !isCompanyActive || isEditingReplaceOrAdjustInvoice) && !enableAllButtonActions"
                      @click="onSendEmailHandle"
                    >
                      {{ $t('invoice.btn.sendEmail') }}
                    </BButton>
                  </BCol>
                  <BCol
                    v-if="!isAdjust && !isReplace && !isDuplicate"
                    cols="3"
                  >
                    <BButton
                      pill
                      class="w-100"
                      variant="warning"
                      :disabled="(!(BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.SIGN || invoiceToEdit.signStatus === 'UNSIGNED') || !isCompanyActive || isEditingReplaceOrAdjustInvoice) && !enableAllButtonActions"
                      @click="onSignInvoiceHandle"
                    >
                      {{ $t('invoice.btn.signAndReleaseInvoice') }}
                    </BButton>
                  </BCol>
                  <BCol
                    v-if="!isAdjust && !isReplace && !isDuplicate"
                    cols="3"
                  >
                    <BButton
                      v-if="!isEditing"
                      pill
                      variant="info"
                      class="w-100"
                      :disabled="(!BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.EDIT || !isCompanyActive) && !enableAllButtonActions"
                      @click="onEditHandle(true)"
                    >
                      {{ $t('invoice.btn.edit') }}
                    </BButton>
                    <div
                      v-else
                      class="d-flex-center gap-3"
                    >
                      <BButton
                        pill
                        variant="info"
                        class="flex-1"
                        :disabled="(!BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.EDIT || !isCompanyActive) && !enableAllButtonActions"
                        @click="onSaveEditHandle"
                      >
                        {{ $t('invoice.btn.edit') }}
                      </BButton>
                      <BButton
                        v-b-tooltip.top.hover.window="$t('invoice.btn.cancelEdit')"
                        pill
                        variant="outline-danger"
                        class="p-50"
                        :disabled="(!BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.EDIT || !isCompanyActive) && !enableAllButtonActions"
                        @click="onEditHandle(false)"
                      >
                        <IAmIcon
                          icon="closeOutline"
                          size="18"
                        />
                      </BButton>
                    </div>
                  </BCol>
                  <BCol
                    v-if="!isAdjust && !isReplace && !isDuplicate"
                    cols="3"
                  >
                    <!-- :order="isReplace || isAdjust ? 1 : 0" -->
                    <BButton
                      pill
                      class="w-100"
                      variant="info"
                      :to="{ name: 'apps-invoicePreview', params: { id: invoiceToEdit.id } }"
                      target="_blank"
                      :disabled="(!BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.VIEW_PDF || isEditingReplaceOrAdjustInvoice) && !enableAllButtonActions"
                    >
                      {{ $t('invoice.btn.preview') }}
                    </BButton>
                  </BCol>
                  <BCol
                    v-if="!isReplace && !isDuplicate"
                    cols="3"
                  >
                    <BButton
                      pill
                      class="w-100"
                      variant="info"
                      :disabled="(
                        !BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.ADJUSTMENT
                        || !isCompanyActive
                        || isEditingReplaceOrAdjustInvoice
                        || invoiceToEdit.referenceReplacedId
                      )
                        && !enableAllButtonActions"
                      @click="onAdjustInvoiceHandle"
                    >
                      {{ $t('invoice.btn.adjustInvoice') }}
                    </BButton>
                  </BCol>

                  <BCol
                    v-if="!isAdjust && !isDuplicate"
                    cols="3"
                  >
                    <BButton
                      pill
                      class="w-100"
                      variant="info"
                      :disabled="(
                        !BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.REPLACEMENT
                        || !isCompanyActive
                        || isEditingReplaceOrAdjustInvoice
                        || invoiceToEdit.referenceAdjustedId
                      )
                        && !enableAllButtonActions"
                      @click="onReplaceInvoiceHandle"
                    >
                      {{ $t('invoice.btn.replaceInvoice') }}
                    </BButton>
                  </BCol>

                  <BCol
                    v-if="!isAdjust && !isReplace"
                    cols="3"
                  >
                    <BButton
                      pill
                      class="w-100"
                      variant="info"
                      :disabled="(
                        !BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.DUPLICATE
                        || !isCompanyActive
                        || isEditingReplaceOrAdjustInvoice
                      )
                        && !enableAllButtonActions"
                      @click="onDuplicateInvoiceHandle"
                    >
                      {{ $t('invoice.btn.duplicateInvoice') }}
                    </BButton>
                  </BCol>
                  <BCol
                    cols="3"
                    :offset="!isAdjust && !isReplace && !isDuplicate ? 3 : 0"
                    :order="isReplace || isAdjust || isDuplicate ? 2 : 0"
                  >
                    <BButton
                      pill
                      class="w-100"
                      variant="outline-secondary"
                      @click="onExitHandle"
                    >
                      {{ $t('invoice.btn.exit') }}
                    </BButton>
                  </BCol>
                  <BCol
                    v-if="!isAdjust && !isReplace && !isDuplicate"
                    cols="3"
                  >
                    <BButton
                      pill
                      class="w-100"
                      variant="danger"
                      :disabled="(
                        !BUTTON_ACTION_PERMISSIONS?.[invoiceToEdit.invoiceStatus]?.[invoiceToEdit.releaseStatus]?.CANCEL
                        || !isCompanyActive
                        || isEditingReplaceOrAdjustInvoice
                      ) && !enableAllButtonActions"
                      @click="onCancelInvoiceHandle"
                    >
                      {{ $t('invoice.btn.cancelInv') }}
                    </BButton>
                  </BCol>
                </BRow>
              </div>
            </BCol>
          </BRow>
          <InvoiceTransmissionHistory
            v-if="!isReplace && !isAdjust && !isDuplicate"
            class="mt-50"
            :history="historyList"
            :auditLogs="invoiceToEdit?.auditLogs || []"
          />
        </div>
        <BAlert
          v-else-if="loading"
          show
          variant="warning"
          class="px-2 py-1"
        >
          {{ $t('loading') }}
        </BAlert>
        <!-- FIXME show error -->
        <!-- <BAlert
          v-else-if="!invoiceToEdit && !loading"
          show
          variant="danger"
          class="px-2 py-1"
        >
          {{ $t('invoice.invalidInvoiceAlert') }}
        </BAlert> -->
      </ValidationObserver>
    </IAmOverlay>

    <InvoiceCancelModal
      :invoice-data="invoiceToEdit"
      @refetch="fetchInvoiceById"
    />

    <InvoiceSignModal
      :invoice-data="invoiceToEdit"
      :companyId="invoiceToEdit?.company?.id ?? null"
      @refetch="fetchInvoiceById"
    />
  </div>
</template>
<script>
import {
  ref, onUnmounted, computed, nextTick, watch,
} from '@vue/composition-api'
import {
  BCol, BRow, BButton, BAlert,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'
import debounce from 'lodash/debounce'

import store from '@/store'
import router from '@/router'
import { useRouter } from '@/@core/utils/utils'
import useInvoiceHandle, { numberToFixed } from '@/views/invoices/useInvoiceHandle'
import invoiceStoreModule from '@/views/invoices/invoiceStoreModule'
import { getToday } from '@/@core/utils/filter'
import { RELEASE_STATUS_VALUES } from '@/constants/invoice'

import formValidation from '@core/comp-functions/forms/form-validation'

import saleReportStoreModule from '@saleReport/saleReportStoreModule'

import useToast from '@useToast'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BAlert,
    ValidationObserver,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    InvoiceInfoCard: () => import('@/views/invoices/components/InvoiceInfoCard.vue'),
    InvoiceSelectTicketCard: () => import('@/views/invoices/components/InvoiceSelectTicketCard.vue'),
    InvoiceDetailCard: () => import('@/views/invoices/components/InvoiceDetailCard.vue'),
    InvoicePaymentCard: () => import('@/views/invoices/components/InvoicePaymentCard.vue'),
    InvoiceTransmissionHistory: () => import('@/views/invoices/components/InvoiceTransmissionHistory.vue'),
    InvoiceCancelModal: () => import('@/views/invoices/components/InvoiceCancelModal.vue'),
    InvoiceSignModal: () => import('@/views/invoices/components/InvoiceSignModal.vue'),
  },
  setup(_, { root }) {
    const { toastError, toastSuccess } = useToast()
    const { refFormObserver } = formValidation()
    const { route } = useRouter()
    const isReplace = computed(() => route.value?.fullPath.split('/')[3] === 'replace' ?? false)
    const isAdjust = computed(() => route.value?.fullPath.split('/')[3] === 'adjust' ?? false)
    const isDuplicate = computed(() => route.value?.fullPath.split('/')[3] === 'duplicate' ?? false)
    const isCompanyActive = computed(() => store.getters['userStore/getAgencyCompany']?.id)
    const {
      getInvoiceById,
      INVOICE_STORE,
      SALE_REPORT_STORE,
      loading,
      loadingGetTemplateId,
      loadingGetCompanyCertificates,
      getTransmissionHistory,
      getTemplateId,
      // releaseInvoice,
      BUTTON_ACTION_PERMISSIONS,
      sendMailInvoice,
      updateInvoice,
      adjustInvoice,
      replaceInvoice,
      duplicateInvoice,
      TAX_RATE_TYPES,
      TAX_TYPES_OBJECT,
      ADJUSTMENT_TYPE,
      INVOICE_STATUS_VALUES,
      GOODS_SERVICE_TYPES, FLIGHT_TYPE,
    } = useInvoiceHandle()

    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)
    if (!store.hasModule(SALE_REPORT_STORE)) store.registerModule(SALE_REPORT_STORE, saleReportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
      if (store.hasModule(SALE_REPORT_STORE)) store.unregisterModule(SALE_REPORT_STORE)
    })

    const isEditing = ref(false)
    const invoiceToEdit = ref()
    const template = ref()

    watch([isReplace, isAdjust, isDuplicate, route], () => {
      if (isReplace.value || isAdjust.value || isDuplicate.value || route.value.hash.includes('#edit')) {
        isEditing.value = true
      }
    }, { immediate: true })

    async function getTemplateInvoice(id) {
      const res = await getTemplateId(id)
      return res?.data.filter(i => i?.active)
    }

    const originInvoice = ref()
    const resetInvoice = ref({ value: uuidv4() }) // biến để reset selected ticket trong detail card
    function initInvoiceData() {
      invoiceToEdit.value = cloneDeep(isAdjust.value ? {
        ...originInvoice.value,
        adjustmentType: null, // INFORMATIONAL_ADJUSTMENT, INCREASE_ADJUSTMENT, DECREASE_ADJUSTMENT
        adjustmentReason: '',
        autoCalculate: false,
      } : originInvoice.value)
      resetInvoice.value.value = uuidv4() // watch bên detail card
    }

    async function fetchInvoiceById(id = null) {
      const res = await getInvoiceById(id ?? router.currentRoute.params.id)
      const companyId = res?.company?.id
      if (!companyId) {
        toastError('invoice.msg.companyNotFound')
        return
      }
      // FIXME duplicate call === info card
      const templateList = await getTemplateInvoice(companyId)
      template.value = templateList.find(i => i.id === res?.invoiceTemplateId)
      if (!template.value) {
        toastError('invoice.msg.templateNotFound')
        return
      }
      originInvoice.value = cloneDeep({
        ...res,
        invoiceItems: res?.invoiceItems.map(invItem => {
          const valueAddedTax = template.value?.taxRateType === TAX_RATE_TYPES.SINGLE ? res.valueAddedTax : invItem?.valueAddedTax
          const amount = template.value?.taxRateType === TAX_RATE_TYPES.SINGLE ? Math.round((invItem?.amount * (100 + TAX_TYPES_OBJECT[valueAddedTax])) / 100) : Math.round(invItem?.amount)
          const unitPrice = template.value?.taxRateType === TAX_RATE_TYPES.SINGLE ? numberToFixed(invItem?.amount) : numberToFixed(invItem?.unitPrice)
          const totalBeforeVat = template.value?.taxRateType === TAX_RATE_TYPES.SINGLE ? Math.round(invItem?.amount) : Math.round(invItem?.totalBeforeVat)
          const vatAmount = template.value?.taxRateType === TAX_RATE_TYPES.SINGLE ? Math.round(amount - unitPrice) : Math.round(invItem?.vatAmount)
          const numberOfSegments = invItem?.saleReport?.ticketType === 'TKT' ? invItem?.saleReport?.trips?.split(' - ').length : 0
          return {
            ...invItem,
            valueAddedTax,
            amount,
            discountAmount: Math.round(invItem?.discountAmount),
            discountRate: Math.round(invItem?.discountRate),
            quantity: Math.round(invItem?.quantity),
            totalBeforeVat,
            unitPrice,
            vatAmount,
            numberOfSegments,
            flightType: invItem?.saleReport
              ? invItem?.saleReport?.flightType
              : (
              invItem?.itemType === GOODS_SERVICE_TYPES.NOTE
                ? invItem?.name === FLIGHT_TYPE.Domestic
                  ? 'Domestic'
                  : (invItem?.name === FLIGHT_TYPE.International ? 'International' : null)
                : null
              ),
          }
        }),
        collectionFee: Math.round(res?.collectionFee),
        totalAmount: Math.round(res?.totalAmount),
        totalAmountBeforeTax: Math.round(res?.totalAmountBeforeTax),
        totalDiscountAmount: Math.round(res?.totalDiscountAmount),
        totalTaxAmount: Math.round(res?.totalTaxAmount),
        totalAmountAdditional: Math.round(res?.totalAmountAdditional),
        templateId: res?.invoiceTemplateId,
        template,
        templateNumber: template.value?.templateNumber,
        symbol: template.value?.symbol,
        discountType: template.value?.discountType,
        taxRateType: template.value?.taxRateType,
        invoiceType: template.value?.invoiceType,
        // thay thế / điều chỉnh / nhân bản thì clear
        note: isReplace.value || isAdjust.value || isDuplicate.value ? '' : res?.note,
        invNumber: isReplace.value || isAdjust.value || isDuplicate.value ? '' : res?.invNumber,
        invOldNumber: isReplace.value || isAdjust.value || isDuplicate.value ? res?.invNumber : '',
        releasedInvCode: isReplace.value || isAdjust.value || isDuplicate.value ? '' : res?.releasedInvCode,
        referenceCode: isReplace.value || isAdjust.value || isDuplicate.value ? '' : res?.referenceCode,
        createdAt: isReplace.value || isAdjust.value || isDuplicate.value ? getToday('YYYY-MM-DD') : res?.createdAt,
        invoiceStatus: isReplace.value || isAdjust.value || isDuplicate.value ? INVOICE_STATUS_VALUES.NEW : res?.invoiceStatus,
        releaseStatus: isReplace.value || isAdjust.value || isDuplicate.value ? RELEASE_STATUS_VALUES.NEW : res?.releaseStatus,
        tags: isReplace.value || isAdjust.value ? [] : res?.tags,
      })
      nextTick(() => {
        initInvoiceData()
        if (res?.totalAmountAdditional && !isAdjust.value && !isReplace.value && !isDuplicate.value) {
          store.dispatch('app-invoice/setNeedToPayAmount', Math.round(res?.totalAmountAdditional))
        }
      })
    }

    watch(route, () => {
      store.dispatch('app-invoice/clearSelectedTickets')
      fetchInvoiceById(route.value.params.id)
    }, { immediate: true })

    const historyList = ref([])

    async function fetchTransmissionHistory() {
      const res = await getTransmissionHistory(router.currentRoute.params.id)
      historyList.value = res.data
    }

    fetchTransmissionHistory()

    const collapseSelectedTicket = ref(false)
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const generateInvoiceNumberOnCreation = computed(() => agencyData.value?.company?.generateInvoiceNumberOnCreation ?? false)

    // actions handle
    function onSignInvoiceHandle() {
      if (generateInvoiceNumberOnCreation.value !== !!invoiceToEdit.value.invNumber) {
        toastError('invoice.msg.differenceGenerateInvoiceNumberOnCreationError')
        return
      }
      root.$bvModal.show('modal-sign-invoice')
    }

    // async function onReleaseHandle() {
    //   const invoiceId = invoiceToEdit.value?.id
    //   if (!invoiceId) return
    //   const res = await releaseInvoice(invoiceId)
    //   if (res?.id) {
    //     fetchInvoiceById(res?.id)
    //   }
    // }

    async function onSendEmailHandle() {
      const invoiceId = invoiceToEdit.value?.id
      if (!invoiceId) return
      await sendMailInvoice(invoiceId)
    }

    const selectedTickets = computed(() => store.getters['app-invoice/getSelectedTickets'])
    const getCollectionFee = computed(() => store.getters['app-invoice/getCollectionFee'])
    const needToPayAmount = computed(() => store.getters['app-invoice/getNeedToPayAmount'])
    const isInvalidInvoiceItems = ref(false)
    watch([selectedTickets, isEditing], () => {
      if ((selectedTickets.value.length || !isEditing.value) && isInvalidInvoiceItems.value) {
        isInvalidInvoiceItems.value = false
      }
    })
    async function onSaveEditHandle() {
      if (!selectedTickets.value.length) {
        isInvalidInvoiceItems.value = true
      }
      const isValid = await refFormObserver.value.validate()
      if (!isValid || isInvalidInvoiceItems.value) return
      const payload = {
        ...invoiceToEdit.value,
        auditLogs: undefined,
        invoiceItems: selectedTickets.value.map(item => ({
          itemType: item.itemType,
          name: `${item.name}`,
          ticketNumber: `${item.ticketNumber}`,
          unit: item.unit ?? '',
          quantity: item.quantity,
          unitPrice: numberToFixed(item.unitPrice ?? 0),
          valueAddedTax: item.valueAddedTax,
          discountRate: 0,
        })),
        templateId: invoiceToEdit.value?.template?.id,
        companyId: invoiceToEdit.value?.company?.id,
        agencyIssueId: invoiceToEdit.value?.agencyIssue?.id,
        agencyReceiveId: invoiceToEdit.value?.agencyReceive?.id,
        collectionFee: getCollectionFee.value,
        totalAmountAdditional: needToPayAmount.value ?? invoiceToEdit.value?.totalAmountAdditional ?? 0,
        // xoá khỏi payload
        template: undefined, // xoá template khỏi payload
        company: undefined, // xoá company khỏi payload
        createdAt: undefined,
        invoiceType: undefined,
        agencyIssue: undefined,
        agencyReceive: undefined,
        invoiceStatus: undefined,
        id: undefined,
      }
      const res = await updateInvoice(invoiceToEdit.value.id, payload)
      // console.log('updateInvoice', { res })
      if (res?.id) {
        // console.log('refetch')
        fetchInvoiceById(res?.id)
        isEditing.value = false
      }
    }

    function onCancelInvoiceHandle() {
      this.$bvModal.show('modal-cancel-invoice')
    }

    const enableAllButtonActions = ref(false)
    function onToggleAllButtonActions() {
      if (process.env.VUE_APP_ENV !== 'development') return
      enableAllButtonActions.value = !enableAllButtonActions.value

      toastSuccess(`${enableAllButtonActions.value ? 'Bật' : 'Tắt'} sáng hết các nút`)
    }

    function onExitHandle() {
      router.push({ name: 'apps-invoiceList' })
    }

    function onEditHandle(val) {
      isEditing.value = val
      if (!val) {
        // reset invoiceData
        initInvoiceData()
      }
    }
    async function onReplaceInvoiceHandle() {
      if (!isReplace.value) {
        store.dispatch('app-invoice/setNeedToPayAmount', 0)
        router.push({ name: 'apps-invoiceReplace', params: { id: invoiceToEdit.value.id } })
        return
      }
      if (!selectedTickets.value.length) {
        isInvalidInvoiceItems.value = true
      }
      const isValid = await refFormObserver.value.validate()
      if (!isValid || isInvalidInvoiceItems.value) return
      // call api replace
      const payload = {
        ...invoiceToEdit.value,
        companyId: invoiceToEdit.value?.company?.id,
        company: undefined,
        invoiceItems: selectedTickets.value.map(item => ({
          itemType: item.itemType,
          name: `${item.name}`,
          ticketNumber: `${item.ticketNumber ?? ''}`,
          unit: item.unit,
          quantity: item.quantity ?? 0,
          unitPrice: numberToFixed(item.unitPrice ?? 0),
          valueAddedTax: item.valueAddedTax,
          discountRate: 0,
        })),
        template: undefined, // xoá template khỏi payload
        invoiceType: undefined,
        agencyIssue: undefined,
        agencyIssueId: invoiceToEdit.value?.agencyIssue?.id,
        agencyReceive: undefined,
        agencyReceiveId: invoiceToEdit.value?.agencyReceive?.id,
        invoiceStatus: undefined,
      }
      const res = await replaceInvoice(invoiceToEdit.value.id, payload)
      if (res?.id) {
        setTimeout(() => {
          router.replace({ name: 'apps-invoiceList' })
        }, 1000)
      }
    }

    async function onAdjustInvoiceHandle() {
      if (!isAdjust.value) {
        store.dispatch('app-invoice/setNeedToPayAmount', 0)
        router.push({ name: 'apps-invoiceAdjust', params: { id: invoiceToEdit.value.id } })
        return
      }

      if (!selectedTickets.value.length) {
        isInvalidInvoiceItems.value = true
      }
      const isValid = await refFormObserver.value.validate()
      if (!isValid || isInvalidInvoiceItems.value) return
      // call api adjust
      const payload = {
        ...invoiceToEdit.value,
        auditLogs: undefined,
        invoiceItems: selectedTickets.value.map(item => ({
          itemType: item.itemType,
          name: `${item.name}`,
          ticketNumber: `${item.ticketNumber ?? ''}`,
          unit: item.unit,
          quantity: item.quantity ?? 0,
          unitPrice: numberToFixed(item.unitPrice ?? 0),
          valueAddedTax: item.valueAddedTax,
          discountRate: 0,
        })),
        templateId: invoiceToEdit.value?.template?.id,
        companyId: invoiceToEdit.value?.company?.id,
        agencyIssueId: invoiceToEdit.value?.agencyIssue?.id,
        agencyReceiveId: invoiceToEdit.value?.agencyReceive?.id,
        collectionFee: getCollectionFee.value,
        totalAmountAdditional: needToPayAmount.value ?? invoiceToEdit.value?.totalAmountAdditional ?? 0,
        // xoá khỏi payload
        template: undefined, // xoá template khỏi payload
        company: undefined, // xoá company khỏi payload
        createdAt: undefined,
        invoiceType: undefined,
        agencyIssue: undefined,
        agencyReceive: undefined,
        invoiceStatus: undefined,
        id: undefined,
      }
      const res = await adjustInvoice(invoiceToEdit.value.id, payload)
      if (res?.id) {
        setTimeout(() => {
          router.replace({ name: 'apps-invoiceList' })
        }, 1000)
      }
    }

    // async function onDuplicateInvoiceHandle() {
    //   const invoiceId = invoiceToEdit.value?.id
    //   if (!invoiceId) return
    //   await duplicateInvoice(invoiceId)
    // }

    async function onDuplicateInvoiceHandle() {
      if (!isDuplicate.value) {
        store.dispatch('app-invoice/setNeedToPayAmount', 0)
        router.push({ name: 'apps-invoiceDuplicate', params: { id: invoiceToEdit.value.id } })
        return
      }

      if (!selectedTickets.value.length) {
        isInvalidInvoiceItems.value = true
      }
      const isValid = await refFormObserver.value.validate()
      if (!isValid || isInvalidInvoiceItems.value) return
      // call api create duplicate
      // FIXME check lai
      const payload = {
        ...invoiceToEdit.value,
        auditLogs: undefined,
        invoiceItems: selectedTickets.value.map(item => ({
          itemType: item.itemType,
          name: `${item.name}`,
          ticketNumber: `${item.ticketNumber ?? ''}`,
          unit: item.unit,
          quantity: item.quantity ?? 0,
          unitPrice: numberToFixed(item.unitPrice ?? 0),
          valueAddedTax: item.valueAddedTax,
          discountRate: 0,
        })),
        templateId: invoiceToEdit.value?.template?.id,
        companyId: invoiceToEdit.value?.company?.id,
        agencyIssueId: invoiceToEdit.value?.agencyIssue?.id,
        agencyReceiveId: invoiceToEdit.value?.agencyReceive?.id,
        collectionFee: getCollectionFee.value,
        totalAmountAdditional: needToPayAmount.value ?? invoiceToEdit.value?.totalAmountAdditional ?? 0,
        // xoá khỏi payload
        template: undefined, // xoá template khỏi payload
        company: undefined, // xoá company khỏi payload
        createdAt: undefined,
        invoiceType: undefined,
        agencyIssue: undefined,
        agencyReceive: undefined,
        invoiceStatus: undefined,
        id: undefined,
        referenceDuplicatedInvoiceId: invoiceToEdit.value?.id,
      }
      const res = await duplicateInvoice(payload)
      if (res?.id) {
        setTimeout(() => {
          router.replace({ name: 'apps-invoiceList' })
        }, 1000)
      }
    }

    const updateInvoiceByField = debounce(({ key, value }) => {
      invoiceToEdit.value[key] = value
    }, 300)

    const isEditingReplaceOrAdjustInvoice = computed(() => isEditing.value && [INVOICE_STATUS_VALUES.REPLACEMENT, INVOICE_STATUS_VALUES.ADJUSTMENT].includes(invoiceToEdit.value.invoiceStatus))
    return {
      invoiceToEdit,
      loading,
      loadingGetTemplateId,
      loadingGetCompanyCertificates,
      collapseSelectedTicket,
      historyList,
      refFormObserver,
      onSignInvoiceHandle,
      // onReleaseHandle,
      BUTTON_ACTION_PERMISSIONS,
      onSendEmailHandle,
      onDuplicateInvoiceHandle,
      onSaveEditHandle,
      onCancelInvoiceHandle,
      fetchInvoiceById,
      onToggleAllButtonActions,
      enableAllButtonActions,
      onExitHandle,
      isEditing,
      onEditHandle,
      resetInvoice,
      isReplace,
      isAdjust,
      isDuplicate,
      onReplaceInvoiceHandle,
      onAdjustInvoiceHandle,
      isInvalidInvoiceItems,
      updateInvoiceByField,
      ADJUSTMENT_TYPE,
      isCompanyActive,
      isEditingReplaceOrAdjustInvoice,
    }
  },
}
</script>
<style lang="scss" scoped>
#invoice-detail ::v-deep {
  .form-group {
    margin-bottom: 0.25rem;
    label,
    legend {
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 1rem;
      // white-space: nowrap;
    }
  }
  .button-actions .col-3 {
    margin-bottom: 0.5rem;
  }
}
</style>
