var render = function () {
  var _vm$invoiceToEdit, _vm$BUTTON_ACTION_PER, _vm$BUTTON_ACTION_PER2, _vm$BUTTON_ACTION_PER3, _vm$BUTTON_ACTION_PER4, _vm$BUTTON_ACTION_PER5, _vm$BUTTON_ACTION_PER6, _vm$BUTTON_ACTION_PER7, _vm$BUTTON_ACTION_PER8, _vm$BUTTON_ACTION_PER9, _vm$BUTTON_ACTION_PER10, _vm$BUTTON_ACTION_PER11, _vm$BUTTON_ACTION_PER12, _vm$BUTTON_ACTION_PER13, _vm$BUTTON_ACTION_PER14, _vm$BUTTON_ACTION_PER15, _vm$BUTTON_ACTION_PER16, _vm$BUTTON_ACTION_PER17, _vm$BUTTON_ACTION_PER18, _vm$BUTTON_ACTION_PER19, _vm$BUTTON_ACTION_PER20, _vm$BUTTON_ACTION_PER21, _vm$BUTTON_ACTION_PER22, _vm$BUTTON_ACTION_PER23, _vm$BUTTON_ACTION_PER24, _vm$BUTTON_ACTION_PER25, _vm$BUTTON_ACTION_PER26, _vm$BUTTON_ACTION_PER27, _vm$BUTTON_ACTION_PER28, _vm$BUTTON_ACTION_PER29, _vm$BUTTON_ACTION_PER30, _vm$invoiceToEdit2, _vm$invoiceToEdit$com, _vm$invoiceToEdit3, _vm$invoiceToEdit3$co;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || _vm.loadingGetTemplateId || _vm.loadingGetCompanyCertificates
    }
  }, [!_vm.isCompanyActive ? _c('BAlert', {
    staticClass: "py-1 px-2",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.msg.companyNotFoundOrDeactivated')) + " ")]) : _vm._e(), _c('ValidationObserver', {
    ref: "refFormObserver"
  }, [_vm.invoiceToEdit ? _c('div', {
    staticClass: "pb-2",
    attrs: {
      "id": "invoice-detail"
    }
  }, [_c('InvoiceInfoCard', {
    attrs: {
      "invoice-data": _vm.invoiceToEdit,
      "isEditing": _vm.isEditing,
      "isAdjust": _vm.isAdjust,
      "isReplace": _vm.isReplace,
      "isDuplicate": _vm.isDuplicate
    },
    on: {
      "update:invoiceData": function updateInvoiceData($event) {
        _vm.invoiceToEdit = $event;
      },
      "update:invoice-data": function updateInvoiceData($event) {
        _vm.invoiceToEdit = $event;
      }
    }
  }), _c('BRow', [_c('BCol', {
    class: !_vm.collapseSelectedTicket ? 'pr-md-50' : '',
    attrs: {
      "cols": "12",
      "md": _vm.collapseSelectedTicket ? 12 : 5
    }
  }, [_c('InvoiceSelectTicketCard', {
    attrs: {
      "invoice-data": _vm.invoiceToEdit,
      "collapse-selected-ticket": _vm.collapseSelectedTicket,
      "isEditing": [_vm.ADJUSTMENT_TYPE.INFORMATIONAL_ADJUSTMENT].includes((_vm$invoiceToEdit = _vm.invoiceToEdit) === null || _vm$invoiceToEdit === void 0 ? void 0 : _vm$invoiceToEdit.adjustmentType) ? false : _vm.isEditing,
      "isAdjust": _vm.isAdjust,
      "isReplace": _vm.isReplace,
      "isDuplicate": _vm.isDuplicate
    },
    on: {
      "update:invoiceData": function updateInvoiceData($event) {
        _vm.invoiceToEdit = $event;
      },
      "update:invoice-data": function updateInvoiceData($event) {
        _vm.invoiceToEdit = $event;
      },
      "update:collapseSelectedTicket": function updateCollapseSelectedTicket($event) {
        _vm.collapseSelectedTicket = $event;
      },
      "update:collapse-selected-ticket": function updateCollapseSelectedTicket($event) {
        _vm.collapseSelectedTicket = $event;
      }
    }
  })], 1), _c('BCol', {
    class: !_vm.collapseSelectedTicket ? 'pl-md-50' : '',
    attrs: {
      "cols": "12",
      "md": _vm.collapseSelectedTicket ? 12 : 7
    }
  }, [_c('InvoiceDetailCard', {
    attrs: {
      "invoice-data": _vm.invoiceToEdit,
      "isEditing": _vm.isEditing,
      "resetInvoice": _vm.resetInvoice,
      "isAdjust": _vm.isAdjust,
      "isReplace": _vm.isReplace,
      "isDuplicate": _vm.isDuplicate,
      "isInvalidInvoiceItems": _vm.isInvalidInvoiceItems
    },
    on: {
      "update:invoiceData": function updateInvoiceData($event) {
        _vm.invoiceToEdit = $event;
      },
      "update:invoice-data": function updateInvoiceData($event) {
        _vm.invoiceToEdit = $event;
      },
      "update-invoice": _vm.updateInvoiceByField
    }
  }), _c('InvoicePaymentCard', {
    attrs: {
      "invoice-data": _vm.invoiceToEdit,
      "isEditing": _vm.isEditing,
      "isAdjust": _vm.isAdjust,
      "isReplace": _vm.isReplace,
      "isDuplicate": _vm.isDuplicate
    },
    on: {
      "update:invoiceData": function updateInvoiceData($event) {
        _vm.invoiceToEdit = $event;
      },
      "update:invoice-data": function updateInvoiceData($event) {
        _vm.invoiceToEdit = $event;
      },
      "toggle-button": _vm.onToggleAllButtonActions
    }
  }), _c('div', {
    staticClass: "d-flex flex-column gap-2 mb-50"
  }, [_c('BRow', {
    staticClass: "button-actions",
    attrs: {
      "align-v": "center"
    }
  }, [_c('BCol', {
    attrs: {
      "cols": "3"
    }
  }, [!_vm.isAdjust && !_vm.isReplace && !_vm.isDuplicate ? _c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "info",
      "disabled": (!((_vm$BUTTON_ACTION_PER = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER !== void 0 && (_vm$BUTTON_ACTION_PER2 = _vm$BUTTON_ACTION_PER[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER2 !== void 0 && (_vm$BUTTON_ACTION_PER3 = _vm$BUTTON_ACTION_PER2[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER3 !== void 0 && _vm$BUTTON_ACTION_PER3.SEND_EMAIL) || !_vm.isCompanyActive || _vm.isEditingReplaceOrAdjustInvoice) && !_vm.enableAllButtonActions
    },
    on: {
      "click": _vm.onSendEmailHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.sendEmail')) + " ")]) : _vm._e()], 1), !_vm.isAdjust && !_vm.isReplace && !_vm.isDuplicate ? _c('BCol', {
    attrs: {
      "cols": "3"
    }
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "warning",
      "disabled": (!((_vm$BUTTON_ACTION_PER4 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER4 !== void 0 && (_vm$BUTTON_ACTION_PER5 = _vm$BUTTON_ACTION_PER4[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER5 !== void 0 && (_vm$BUTTON_ACTION_PER6 = _vm$BUTTON_ACTION_PER5[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER6 !== void 0 && _vm$BUTTON_ACTION_PER6.SIGN || _vm.invoiceToEdit.signStatus === 'UNSIGNED') || !_vm.isCompanyActive || _vm.isEditingReplaceOrAdjustInvoice) && !_vm.enableAllButtonActions
    },
    on: {
      "click": _vm.onSignInvoiceHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.signAndReleaseInvoice')) + " ")])], 1) : _vm._e(), !_vm.isAdjust && !_vm.isReplace && !_vm.isDuplicate ? _c('BCol', {
    attrs: {
      "cols": "3"
    }
  }, [!_vm.isEditing ? _c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "info",
      "disabled": (!((_vm$BUTTON_ACTION_PER7 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER7 !== void 0 && (_vm$BUTTON_ACTION_PER8 = _vm$BUTTON_ACTION_PER7[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER8 !== void 0 && (_vm$BUTTON_ACTION_PER9 = _vm$BUTTON_ACTION_PER8[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER9 !== void 0 && _vm$BUTTON_ACTION_PER9.EDIT) || !_vm.isCompanyActive) && !_vm.enableAllButtonActions
    },
    on: {
      "click": function click($event) {
        return _vm.onEditHandle(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.edit')) + " ")]) : _c('div', {
    staticClass: "d-flex-center gap-3"
  }, [_c('BButton', {
    staticClass: "flex-1",
    attrs: {
      "pill": "",
      "variant": "info",
      "disabled": (!((_vm$BUTTON_ACTION_PER10 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER10 !== void 0 && (_vm$BUTTON_ACTION_PER11 = _vm$BUTTON_ACTION_PER10[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER11 !== void 0 && (_vm$BUTTON_ACTION_PER12 = _vm$BUTTON_ACTION_PER11[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER12 !== void 0 && _vm$BUTTON_ACTION_PER12.EDIT) || !_vm.isCompanyActive) && !_vm.enableAllButtonActions
    },
    on: {
      "click": _vm.onSaveEditHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.edit')) + " ")]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top.hover.window",
      value: _vm.$t('invoice.btn.cancelEdit'),
      expression: "$t('invoice.btn.cancelEdit')",
      modifiers: {
        "top": true,
        "hover": true,
        "window": true
      }
    }],
    staticClass: "p-50",
    attrs: {
      "pill": "",
      "variant": "outline-danger",
      "disabled": (!((_vm$BUTTON_ACTION_PER13 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER13 !== void 0 && (_vm$BUTTON_ACTION_PER14 = _vm$BUTTON_ACTION_PER13[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER14 !== void 0 && (_vm$BUTTON_ACTION_PER15 = _vm$BUTTON_ACTION_PER14[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER15 !== void 0 && _vm$BUTTON_ACTION_PER15.EDIT) || !_vm.isCompanyActive) && !_vm.enableAllButtonActions
    },
    on: {
      "click": function click($event) {
        return _vm.onEditHandle(false);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "closeOutline",
      "size": "18"
    }
  })], 1)], 1)], 1) : _vm._e(), !_vm.isAdjust && !_vm.isReplace && !_vm.isDuplicate ? _c('BCol', {
    attrs: {
      "cols": "3"
    }
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "info",
      "to": {
        name: 'apps-invoicePreview',
        params: {
          id: _vm.invoiceToEdit.id
        }
      },
      "target": "_blank",
      "disabled": (!((_vm$BUTTON_ACTION_PER16 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER16 !== void 0 && (_vm$BUTTON_ACTION_PER17 = _vm$BUTTON_ACTION_PER16[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER17 !== void 0 && (_vm$BUTTON_ACTION_PER18 = _vm$BUTTON_ACTION_PER17[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER18 !== void 0 && _vm$BUTTON_ACTION_PER18.VIEW_PDF) || _vm.isEditingReplaceOrAdjustInvoice) && !_vm.enableAllButtonActions
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.preview')) + " ")])], 1) : _vm._e(), !_vm.isReplace && !_vm.isDuplicate ? _c('BCol', {
    attrs: {
      "cols": "3"
    }
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "info",
      "disabled": (!((_vm$BUTTON_ACTION_PER19 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER19 !== void 0 && (_vm$BUTTON_ACTION_PER20 = _vm$BUTTON_ACTION_PER19[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER20 !== void 0 && (_vm$BUTTON_ACTION_PER21 = _vm$BUTTON_ACTION_PER20[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER21 !== void 0 && _vm$BUTTON_ACTION_PER21.ADJUSTMENT) || !_vm.isCompanyActive || _vm.isEditingReplaceOrAdjustInvoice || _vm.invoiceToEdit.referenceReplacedId) && !_vm.enableAllButtonActions
    },
    on: {
      "click": _vm.onAdjustInvoiceHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.adjustInvoice')) + " ")])], 1) : _vm._e(), !_vm.isAdjust && !_vm.isDuplicate ? _c('BCol', {
    attrs: {
      "cols": "3"
    }
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "info",
      "disabled": (!((_vm$BUTTON_ACTION_PER22 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER22 !== void 0 && (_vm$BUTTON_ACTION_PER23 = _vm$BUTTON_ACTION_PER22[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER23 !== void 0 && (_vm$BUTTON_ACTION_PER24 = _vm$BUTTON_ACTION_PER23[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER24 !== void 0 && _vm$BUTTON_ACTION_PER24.REPLACEMENT) || !_vm.isCompanyActive || _vm.isEditingReplaceOrAdjustInvoice || _vm.invoiceToEdit.referenceAdjustedId) && !_vm.enableAllButtonActions
    },
    on: {
      "click": _vm.onReplaceInvoiceHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.replaceInvoice')) + " ")])], 1) : _vm._e(), !_vm.isAdjust && !_vm.isReplace ? _c('BCol', {
    attrs: {
      "cols": "3"
    }
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "info",
      "disabled": (!((_vm$BUTTON_ACTION_PER25 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER25 !== void 0 && (_vm$BUTTON_ACTION_PER26 = _vm$BUTTON_ACTION_PER25[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER26 !== void 0 && (_vm$BUTTON_ACTION_PER27 = _vm$BUTTON_ACTION_PER26[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER27 !== void 0 && _vm$BUTTON_ACTION_PER27.DUPLICATE) || !_vm.isCompanyActive || _vm.isEditingReplaceOrAdjustInvoice) && !_vm.enableAllButtonActions
    },
    on: {
      "click": _vm.onDuplicateInvoiceHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.duplicateInvoice')) + " ")])], 1) : _vm._e(), _c('BCol', {
    attrs: {
      "cols": "3",
      "offset": !_vm.isAdjust && !_vm.isReplace && !_vm.isDuplicate ? 3 : 0,
      "order": _vm.isReplace || _vm.isAdjust || _vm.isDuplicate ? 2 : 0
    }
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.onExitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.exit')) + " ")])], 1), !_vm.isAdjust && !_vm.isReplace && !_vm.isDuplicate ? _c('BCol', {
    attrs: {
      "cols": "3"
    }
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "danger",
      "disabled": (!((_vm$BUTTON_ACTION_PER28 = _vm.BUTTON_ACTION_PERMISSIONS) !== null && _vm$BUTTON_ACTION_PER28 !== void 0 && (_vm$BUTTON_ACTION_PER29 = _vm$BUTTON_ACTION_PER28[_vm.invoiceToEdit.invoiceStatus]) !== null && _vm$BUTTON_ACTION_PER29 !== void 0 && (_vm$BUTTON_ACTION_PER30 = _vm$BUTTON_ACTION_PER29[_vm.invoiceToEdit.releaseStatus]) !== null && _vm$BUTTON_ACTION_PER30 !== void 0 && _vm$BUTTON_ACTION_PER30.CANCEL) || !_vm.isCompanyActive || _vm.isEditingReplaceOrAdjustInvoice) && !_vm.enableAllButtonActions
    },
    on: {
      "click": _vm.onCancelInvoiceHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.cancelInv')) + " ")])], 1) : _vm._e()], 1)], 1)], 1)], 1), !_vm.isReplace && !_vm.isAdjust && !_vm.isDuplicate ? _c('InvoiceTransmissionHistory', {
    staticClass: "mt-50",
    attrs: {
      "history": _vm.historyList,
      "auditLogs": ((_vm$invoiceToEdit2 = _vm.invoiceToEdit) === null || _vm$invoiceToEdit2 === void 0 ? void 0 : _vm$invoiceToEdit2.auditLogs) || []
    }
  }) : _vm._e()], 1) : _vm.loading ? _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('loading')) + " ")]) : _vm._e()], 1)], 1), _c('InvoiceCancelModal', {
    attrs: {
      "invoice-data": _vm.invoiceToEdit
    },
    on: {
      "refetch": _vm.fetchInvoiceById
    }
  }), _c('InvoiceSignModal', {
    attrs: {
      "invoice-data": _vm.invoiceToEdit,
      "companyId": (_vm$invoiceToEdit$com = (_vm$invoiceToEdit3 = _vm.invoiceToEdit) === null || _vm$invoiceToEdit3 === void 0 ? void 0 : (_vm$invoiceToEdit3$co = _vm$invoiceToEdit3.company) === null || _vm$invoiceToEdit3$co === void 0 ? void 0 : _vm$invoiceToEdit3$co.id) !== null && _vm$invoiceToEdit$com !== void 0 ? _vm$invoiceToEdit$com : null
    },
    on: {
      "refetch": _vm.fetchInvoiceById
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }